<template>
  <div class="home">
    <v-container fluid class="loginOverlay fill-height">
      <v-row class="text-center" justify="center">
        <v-col cols="auto">
          <h1 class="text-h1 primary--text">Page not found</h1>

          <v-btn
            class="my-5"
            x-large
            color="secondary"
            @click="hasHistory() ? $router.go(-1) : $router.push('/')"
          >
            Get me out of here!
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Error",
  data() {
    return {};
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100vh;
  // background-image: url("../assets/img/medsafu_hd_bg.png");
  background-size: cover;
  overflow: hidden;
}
</style>
